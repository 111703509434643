.row-div {
    display: flex;
    flex-direction: row;
}

.search-buttons {
    display: table;
    margin: 0 auto;
}

.search-input-boxes {
    display: flex;
    flex-direction: row;

    .inner-div {
        padding-left: 10px;

        .radio-button-group {
            padding-top: 5px;
            padding-left: 5px;
            vertical-align: middle;

            input {
                margin: 5px;
            }

            label {
                margin: 5px;
            }
        }
    }
}