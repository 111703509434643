@import "bootstrap.scss";
@import "theme/variables";
@import "theme/admin_style";
@import "theme/rtl";
@import "flag-icon.scss";
@import "themify.scss";
@import "icoicon/icons";
@import "font-awesome.scss";
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url('https://fonts.googleapis.com/css2?family=KoHo&display=swap');
@import "order.scss";

//spinner
.cssload-whirlpool,
.cssload-whirlpool::before,
.cssload-whirlpool::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid #fff;
    border-left-color: $primary-color;
    border-radius: 974px;
    -o-border-radius: 974px;
    -ms-border-radius: 974px;
    -webkit-border-radius: 974px;
    -moz-border-radius: 974px;
}

.cssload-whirlpool {
    margin: -24px 0 0 -24px;
    height: 49px;
    width: 49px;
    animation: cssload-rotate 1150ms linear infinite;
    -o-animation: cssload-rotate 1150ms linear infinite;
    -ms-animation: cssload-rotate 1150ms linear infinite;
    -webkit-animation: cssload-rotate 1150ms linear infinite;
    -moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload-whirlpool::before {
    content: "";
    margin: -22px 0 0 -22px;
    height: 43px;
    width: 43px;
    animation: cssload-rotate 1150ms linear infinite;
    -o-animation: cssload-rotate 1150ms linear infinite;
    -ms-animation: cssload-rotate 1150ms linear infinite;
    -webkit-animation: cssload-rotate 1150ms linear infinite;
    -moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload-whirlpool::after {
    content: "";
    margin: -28px 0 0 -28px;
    height: 55px;
    width: 55px;
    animation: cssload-rotate 2300ms linear infinite;
    -o-animation: cssload-rotate 2300ms linear infinite;
    -ms-animation: cssload-rotate 2300ms linear infinite;
    -webkit-animation: cssload-rotate 2300ms linear infinite;
    -moz-animation: cssload-rotate 2300ms linear infinite;
}

@keyframes cssload-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes cssload-rotate {
    100% {
        -o-transform: rotate(360deg);
    }
}

@-ms-keyframes cssload-rotate {
    100% {
        -ms-transform: rotate(360deg);
    }
}

@-webkit-keyframes cssload-rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes cssload-rotate {
    100% {
        -moz-transform: rotate(360deg);
    }
}

//overlay
.overlay-spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #212121;
    opacity: 0.5;
    display: none;
}

.lists-custom {
    width: 100%;
    overflow-x: auto;

    // overflow-y: scroll;
    // height: 100vh;
    table {
        text-align: center;

        thead {
            tr {
                th {
                    border: 2.5px solid #c2c2c2;
                    padding: 10px 10px 10px 10px;
                    // position: sticky;
                    // top: 0;
                    background-color: #ddd;
                }
            }
        }

        tbody {
            tr {
                td {
                    border: 1px solid #c2c2c2;
                    padding: 2.5px 10px 2.5px 10px;

                    .aicon {
                        font-size: 30px;
                        color: #ff808449;
                        cursor: pointer;
                        transition: 0.3s;

                        &:hover {
                            color: #ff8084;
                        }
                    }

                    .alink {
                        font-size: 14px;
                        color: #7300ff !important;
                        cursor: pointer;
                        transition: 0.3s;

                        &:hover {
                            color: #db4848 !important;
                        }
                    }
                }
            }
        }
    }
}

.custom-order-details {
    h4 {
        font-weight: 800;

        span {
            font-weight: 500;
            color: #656565;
        }
    }

    ul {
        li {
            display: block;
            font-weight: 500;

            span {
                font-weight: 600;
            }
        }

        .imp {
            font-weight: 600;
        }
    }

    .order-statuss {
        display: flex;
        justify-content: center;

        form {
            h4 {
                padding-top: 25px;
                color: $primary-color;

                strong {
                    color: #212121;
                }
            }

            select {
                width: 200px;
            }
        }
    }
}

.color-display {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: transform 0.25s;

    &:hover {
        z-index: 1;
        transform: scale(1.8);
    }
}

.c-color-swatch {
    display: table;
    width: 25px;
    height: 25px;
    flex-flow: wrap;
    margin: 3px 3px 3px 3px;

    // table-layout: fixed;
    // max-width: 250px;

    &__item {
        display: table-cell;
        // height: 16px;
        // line-height: 16px;
        text-align: center;
        // user-select: none;
        position: relative;
        transition: transform 0.25s;
        cursor: pointer;

        &:hover {
            z-index: 1;
            transform: scale(1.5);
        }
    }

    [type="checkbox"]:checked+label:after {
        content: "\f00c";
        font-size: 15px;
        vertical-align: middle;
        color: #fff;
        font-family: FontAwesome;
    }
}

.address-box {
    width: 650px;
    position: relative;

    img {
        width: 100%;
    }

    .one {
        width: 390px;
        position: absolute;
        top: 92px;
        left: 200px;
        transform: translate(0%, 0%);

        // background-color: #c96;
        h3 {
            font-family: "Anton", sans-serif;
            color: #313131;
        }
    }

    .two {
        width: 390px;
        position: absolute;
        top: 308px;
        left: 150px;
        transform: translate(0%, 0%);

        h6 {
            font-family: "Anton", sans-serif;
            color: #313131;
            margin-bottom: 0rem;
            font-weight: 550;
            line-height: 22px !important;
        }
    }
}

.filters-custom {
    display: flex;
    justify-content: center;

    .order-status-filter {
        margin-right: 30px;
    }

    .payment-status-filter {
        margin-left: 30px;
    }
}

.page-wrapper .page-main-header.open {
    z-index: 11 !important;
}

.page-wrapper .page-main-header {
    z-index: 11 !important;
}

.fancybtn {
    transition: 0.3s;

    &:hover {
        color: red !important;
    }
}

.cust-img-cont {
    position: relative;
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }

    .btn-cust-del {
        position: absolute;
        top: 12%;
        left: 96%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        background-color: white;
        width: 45px;
        height: 45px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        cursor: pointer;
        transition: 0.3s;
        color: gray;

        &:hover {
            background-color: #eea287;
            color: white;
        }

        &.btn-cust-del {
            @media only screen and (max-width: 1280px) {
                width: 40px;
                height: 40px;
            }

            @media only screen and (max-width: 1100px) {
                width: 35px;
                height: 35px;
            }

            @media only screen and (max-width: 750px) {
                width: 30px;
                height: 30px;
            }

            @media only screen and (max-width: 600px) {
                width: 20px;
                height: 20px;
            }
        }

        .svg-cust-del {
            width: 24px;
            height: 24px;

            &.svg-cust-del {
                @media only screen and (max-width: 1280px) {
                    width: 24px;
                    height: 24px;
                }

                @media only screen and (max-width: 1100px) {
                    width: 22px;
                    height: 22px;
                }

                @media only screen and (max-width: 750px) {
                    width: 19px;
                    height: 19px;
                }

                @media only screen and (max-width: 600px) {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    .btn-cust-upload {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        background-color: white;
        width: 75px;
        height: 75px;
        border-radius: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        cursor: pointer;
        transition: 0.3s;
        color: gray;

        &:hover {
            background-color: #eea287;
            color: white;
        }

        &.btn-cust-upload {
            @media only screen and (max-width: 1280px) {
                width: 60px;
                height: 60px;
            }

            @media only screen and (max-width: 1100px) {
                width: 53px;
                height: 53px;
            }

            @media only screen and (max-width: 750px) {
                width: 30px;
                height: 30px;
            }

            @media only screen and (max-width: 600px) {
                width: 20px;
                height: 20px;
            }
        }

        .svg-cust-upload {
            width: 40px;
            height: 40px;

            &.svg-cust-upload {
                @media only screen and (max-width: 1280px) {
                    width: 31px;
                    height: 31px;
                }

                @media only screen and (max-width: 1100px) {
                    width: 25px;
                    height: 25px;
                }

                @media only screen and (max-width: 750px) {
                    width: 19px;
                    height: 19px;
                }

                @media only screen and (max-width: 600px) {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}

.font-koho {
    font-family: 'KoHo', sans-serif;
}

.bg-cust {
    background-color: #ff9a9a !important;
}

.font-cust {
    color: #ff9a9a !important;
}

.c-white {
    color: white;
}